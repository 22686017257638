import React, { useState } from "react";
import "./Archives.css";

const Archives = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="archives-container">
      <div className={`float-button ${isExpanded ? "expanded" : ""}`} onClick={handleClick}>
        <i className="fa fa-archive"></i>
      </div>
      {isExpanded && (
        <ul className="archives-content">
        <li>February 2023</li>
        
      </ul>
      )}
    </div>
  );
};

export default Archives;
