import React from "react";
import "./Pagination.css";

const Pagination = ({ totalPages, currentPage, handlePageClick }) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="pagination" style={{paddingRight:"15%"}}>
      {pageNumbers.map(number => (
        <li
          key={number}
          className={currentPage === number ? "active" : ""}
          onClick={() => handlePageClick(number)}
        >
          {number}
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
