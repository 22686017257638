import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Packages extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  componentDidUpdate(){
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <div className="page-area">
          <div className="breadcumb-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb text-center">
                  <div className="section-headline white-headline text-center">
                    <h3>Packages</h3>
                  </div>
                  {/* <ul>
                    <li className="home-bread">Home</li>
                    <li>Solutions</li>
                    <li>Packages</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-services-page area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="page-head-left">
                  <div className="single-page-head">
                    <div className="left-menu">
                      <ul>
                        <li  className="active">
                          <Link to="/packages">Packages</Link>
                        </li>
                        <li>
                          <Link to="/integrations">Integration Services</Link>
                        </li>
                        <li>
                          <Link to="/business-transformation">
                            Digital Transformations
                          </Link>
                        </li>
                        <li>
                          <Link to="/implementation">Implementations</Link>
                        </li>
                        <li>
                          <Link to="/managed-services">Managed Services</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm-8 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="single-well mar-well">
                      
                      <h4>PREMIERE OR PREMIERE PLUS</h4>
                      <p align="justify">
                        Our Premiere and Premiere Plus offerings are Rapid
                        Deployment service offerings (including methodology and
                        supporting tools) for delivering out-of-the-box SAP®
                        SuccessFactors® to customers quickly and
                        cost-effectively.{' '}
                      </p>
                      <p align="justify">
                        Our packages are based on SAP’s rapid deployment module
                        offerings coupled with EA Labs’s experience and
                        recommended practices.
                      </p>
                      <p align="justify">
                        They can be deployed to any size customer, US or global,
                        but are most beneficial to customers who want to get up
                        and running quickly by building the proper foundation.{' '}
                      </p>

                      <h5>PLATINUM</h5>
                      <p align="justify">
                        This implementation is a bit more custom than our
                        Premiere or Premiere Plus options. It still comes with
                        our foundational best practice framework and from there
                        we configure based on your individual needs. This option
                        includes increased functionality and flexibility along
                        with full project management. You will receive
                        everything you need to ensure a successful and smooth
                        go-live!{' '}
                      </p>

                      <h5>DIAMOND</h5>
                      <p align="justify">
                        {' '}
                        Our Diamond package is the most customizable option.
                        You’ll still receive the solid foundation basics to get
                        you up and running. Project management is included along
                        with increased flexibility, and personalization!{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-area bg-color fix area-padding">
          <div className="container">
            <div className="row">
              <div className="single-machine row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="machine-text text-center">
                    <h3>QUESTIONS? WE’VE GOT ANSWERS.</h3>
                    <p className="text-center">
                      You don’t learn everything in life by reading a manual.
                      So, if you have questions about our products, services,
                      implementation, getting started, or anything else, please
                      ask us. Our highly trained staff is ready to help.
                    </p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Contact Us
                    </Link>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Packages;
