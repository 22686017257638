import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HubspotNewsLetter from '../components/HubspotNewsLetter';

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer1">
          <div className="footer-area">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-sm-4 col-xs-12">
                  <div className="footer-content logo-footer">
                    <div className="footer-head">
                      <div className="footer-logo">
                        <Link to="/">
                          <img src="img/logo/logo.png" alt="" />
                        </Link>
                      </div>
                      <p>
                      As "Warriors of the Cloud," we will enable your
                      HR team to transform your enterprise using
                      human capital management services.
                      </p>
                      {/* <div>
                     <HubspotNewsLetter />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-xs-12">
                  <div className="footer-content">
                    <div className="footer-head">
                      <h4>Services Link</h4>
                      <ul className="footer-list">
                        <li>
                          <Link to="/about-company">About Us</Link>
                        </li>
                        <li>
                          <Link to="/packages">Solutions </Link>
                        </li>

                        <li>
                         <Link to="/blog">Blog</Link>
                           
                        </li>
                        <li>
                          <Link to="/contact-us"   >Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/sitemap">Sitemap</Link>
                        </li>
                        <li>
                          <a
                            href='https://application.chachapoyahxm.com'
                            targt="_blank"
                          >
                            Employee Login
                          </a>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-3 col-xs-12">
                  <div className="footer-content">
                    <div className="footer-head">
                      <h4>Locations</h4>
                      <ul className="footer-list" style={{marginTop:'10px'}}>
                        <li style={{lineHeight:'0.8px'}}>
                          <p>
                           US Corp – Parker 
                           </p>
                        </li>
                         
                        <li style={{lineHeight:'30px'}}>
                           <p>
                            US Operations – Chicago 
                             </p>
                        </li>
                        <li style={{lineHeight:'7px'}}>
                          <p>
                           India Corp and Lab – Guntur 
                           </p>
                        </li>
                        <li>
                           <p>
                            India Offices – Bangalore, Hyderabad, Pondicherry
                           </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-xs-12">
                  <div className="footer-content last-content">
                    <div className="footer-head">
                      <h4>Information</h4>
                      <div className="footer-contacts">
                        <p>
                          <span>Location :</span> 10233 SOUTH PARKER ROAD, SUITE
                          300 PARKER, CO 80134
                        </p>
                        <p>
                          <span>Tel :</span>+1 303.400.6401
                        </p>
                        <p>
                          <span>Email :</span> info@chachapoyahxm.com
                        </p>
                      </div>
                      <div className="footer-icons">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/ChachapoyaConsulting"
                              target="_blank"
                            >
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/chachapoya-consulting-llc/mycompany/"
                              target="_blank"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/chachapoyahxm" target="_blank">
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                <HubspotNewsLetter />

                </div>
              </div>
            </div>
          </div>
          <div className="footer-area-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="copyright">
                    <p>Copyright © 2023. All Rights Reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
