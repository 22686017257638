import React, { Component } from 'react';

class WhyWe extends Component {
  render() {
    return (
      <div>
        <div className="page-area">
          <div className="breadcumb-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb text-center">
                  <div className="section-headline white-headline text-center">
                    <h3>Why We Are Different</h3>
                  </div>
                  {/* <ul>
                    <li className="home-bread">Home</li>
                    <li>About Us</li>
                    <li>Why We are Different</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-area bg-color fix area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="tab-content">
                  <div className="tab-pane active" id="p-view-1">
                    <div className="tab-inner">
                      <div className="single-machine row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="tabe-img">
                            <img
                              src="img/background/diferrent.jpg"
                              className="img-responsive"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="machine-text">
                            <h3>
                              WE EMPOWER YOUR HXM TEAM TO TRANSFORM YOUR
                              ENTERPRISE
                            </h3>
                            <p align="justify">
                              CHACHAPOYA HXM was born in the cloud in 2015. We
                              are one of the only partners solely focused on
                              SAP®SuccessFactors®. Over the years, we have
                              helped companies of all sizes transform HXM
                              systems and processes, realize higher returns from
                              their talent management investments and transform
                              their organizations.
                            </p>
                            <h3>
                              GET YOUR HEAD – AND YOUR CAREER – IN THE CLOUD!
                            </h3>
                            <p align="justify">
                              With a mission to provide the platform and cloud
                              services that put ALL employees first, EA Labs
                              employees are awarded freedom and responsibility.
                              Our belief is that happy and challenged employees
                              make for satisfied customers and a successful
                              company.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhyWe;
