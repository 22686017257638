import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sitemap extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  componentDidUpdate(){
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <div class="page-area">
          <div class="breadcumb-overlay"></div>
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb text-center">
                  <div class="section-headline white-headline text-center">
                    <h3>Sitemap</h3>
                  </div>
                  {/* <ul>
                    <li class="home-bread">Home</li>
                    <li>Sitemap</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container" style={{ marginTop: '30px' }}>
          <div class="row">
            <div class="blog-left-content">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="single-blog ">
                  <div
                    class="col-md-2"
                    style={{
                      borderRight: '1px solid #ccc',
                      minHeight: '200px',
                    }}
                  >
                    <Link to="/">
                      <strong>Home</strong>
                    </Link>
                  </div>

                  <div
                    class="col-md-2"
                    style={{
                      borderRight: '1px solid #ccc',
                      minHeight: '200px',
                    }}
                  >
                    <strong> About Us</strong>

                    <ul>
                      <li>
                        <Link to="/about-company">About Company</Link>
                      </li>
                      <li>
                        <Link to="/why-we">Why We are Different</Link>
                      </li>
                      <li>
                        <Link to="/partners">Partners</Link>
                      </li>
                      <li>
                        <Link to="/careers">Careers</Link>
                      </li>
                    </ul>
                  </div>

                  <div
                    class="col-md-2"
                    style={{
                      borderRight: '1px solid #ccc',
                      minHeight: '200px',
                    }}
                  >
                    <strong>Solutions</strong>
                    <br />
                    <ul>
                      <li>
                        <Link to="/packages">Packages</Link>
                      </li>
                      <li>
                        <Link to="/integrations">Integrations</Link>
                      </li>
                      <li>
                        <Link to="/business-transformation">
                          Business Transformation
                        </Link>
                      </li>
                      <li>
                        <Link to="/implementation">Implementation</Link>
                      </li>
                      <li>
                        <Link to="/managed-services">Managed</Link>
                      </li>
                    </ul>
                  </div>

                  <div
                    class="col-md-2"
                    style={{
                      borderRight: '1px solid #ccc',
                      minHeight: '200px',
                    }}
                  >
                    <Link to="/advantages">
                      <strong>Advantages</strong>
                    </Link>
                  </div>

                  <div
                    class="col-md-2"
                    style={{
                      borderRight: '1px solid #ccc',
                      minHeight: '200px',
                    }}
                  >
                    <a href="https://chachapoyahcmdoodles.com/">
                      <strong>Our Blog</strong>
                    </a>
                  </div>

                  <div class="col-md-2">
                    <Link to="/contact-us">
                      <strong>Contact Us</strong>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sitemap;
