import React, { Component } from "react";
import '../hubspotform.css';

class HubspotNewsLetter extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      window.hbspt.forms.create({
        portalId: "8728422",
        formId: "d56019d5-3c43-4612-b408-098eb3949d54",
        target: "#your-form-container-id",
      });
    });
  }

  render() {
    return (
      <div className="container" style={{marginTop: '-10px', paddingRight:'50%'}}>
      {/* <h2 className="text-center" style={{color:'rgb(36, 53, 89)',fontSize:'24px', width:"fit-content"}}>SCHEDULE A FRddEE CONSULTATION</h2> */}
      <div className="row">
        <div class="col-md-12 col-sm-12 col-xs-12" formdiv="true"  style={{marginTop: '25px'}}>
          <div id="your-form-container-id"></div>
        </div>
      </div>
    </div>

    );
  }
}

export default HubspotNewsLetter;