import React from 'react';
import { BrowserRouter as Router , Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import Footer from './common/Footer';
import Header from './common/Header';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Button from './components/Button';
import HubspotForm from './components/HubspotForm';
import Blog from './components/blogs/Blog'
// import Home from './components/Home'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>h
      <Header></Header>
      <Route path="/hubspotform" component={HubspotForm} />
      
      <Route path="/blog" component={Blog} />
      <Footer></Footer>
      <Route exact path="/" component={Button} />
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
