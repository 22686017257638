import React, { Component } from 'react';

class Careers extends Component {
  render() {
    return (
      <div>
        <div className="page-area">
          <div className="breadcumb-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb text-center">
                  <div className="section-headline white-headline text-center">
                    <h3>About Chachapoya</h3>
                  </div>
                  <ul>
                    <li className="home-bread">Home</li>
                    <li>About Us</li>
                    <li>Careers</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-area bg-color fix area-padding">
          <div className="container">
            <div className="row">
              <div className="single-machine row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="machine-text text-center">
                    <h3>JOIN OUR TEAM EMPOWER THE ENTERPRISE</h3>
                    <p className="text-center">
                      By seamlessly dovetailing our mission to provide the
                      technology and services that put our customers’ employees
                      first, and our vision to always help our customers
                      optimize their employees’ health, wealth and performance,
                      we deliver HR as a Service to help transform your HR
                      operations for workforce competitive advantage.
                    </p>
                    <p className="text-center">
                      We focus on effective and cost-efficient delivery of human
                      capital and benefits services so you can focus on
                      strategies for developing, retaining and rewarding
                      employees objectively and consistently.
                    </p>

                    <p className="text-center">
                      Our efforts have helped companies of all sizes transform
                      HR systems and processes, so they realize higher returns
                      from their talent management investments and create
                      competitive workforces that deliver results.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="welcome-area fix area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="all-wel-services">
                  <div
                    className="well-services wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{paddingTop:"20px"}}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="#">
                        <i className="flaticon-052-project-plan"></i>
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h5>EXPERIENCED</h5>
                        <p>
                          We speak in a knowledgeable and easy to understand
                          way, helping clients understand the intricacies of the
                          platform and processes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="well-services wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{paddingTop:"20px"}}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="#">
                        <i className="flaticon-058-suitcase"></i>
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h5>COLLABORATIVE</h5>
                        <p>
                          We are a true partner. We empower our clients to
                          transform their business by being their confidant,
                          advisor and biggest supporter.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="well-services wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{paddingTop:"20px"}}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="#">
                        <i className="flaticon-062-workflow"></i>
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h5>INNOVATIVE</h5>
                        <p>
                          We explore new ideas and brainstorm concepts alongside
                          our clients to develop the best possible solution for
                          their unique business.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="well-services wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{paddingTop:"20px"}}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="#">
                        <i className="flaticon-034-hr-manager"></i>
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h5>AGILE</h5>
                        <p>
                          We understand the need to show results quickly. We are
                          quick to respond to client’s needs and hit target
                          dates and goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-area bg-color fix area-padding">
          <div className="container">
            <div className="row">
              <div className="single-machine row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="machine-text text-center">
                    <h3>QUESTIONS? WE’VE GOT ANSWERS.</h3>
                    <p className="text-center">
                      You don’t learn everything in life by reading a manual.
                      So, if you have questions about our products, services,
                      implementation, getting started, or anything else, please
                      ask us. Our highly trained staff is ready to help.
                    </p>
                    <a href="#" className="btn btn-danger">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Careers;
