import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ManagedServices extends Component {
  render() {
    return (
      <div>
        <div className="page-area">
          <div className="breadcumb-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb text-center">
                  <div className="section-headline white-headline text-center">
                    <h3>Managed Services</h3>
                  </div>
                  {/* <ul>
                   <li className="home-bread">Home</li>
                    <li>Solutions</li>
                    <li>Managed Services</li>
                  </ul>  */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-services-page area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="page-head-left">
                  <div className="single-page-head">
                    <div className="left-menu">
                      <ul>
                        <li >
                          <Link to="/packages">Packages</Link>
                        </li>
                        <li>
                          <Link to="/integrations">Integration Services</Link>
                        </li>
                        <li>
                          <Link to="/business-transformation">
                            Digital Transformations
                          </Link>
                        </li>
                        <li>
                          <Link to="/implementation">Implementations</Link>
                        </li>
                        <li  className="active">
                          <Link to="/managed-services">Managed Services</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm-8 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="single-well mar-well">
                      <h4>SERVICES</h4>
                      <p align="justify">
                        Get the most value out of your SAP HCM solution and
                        SuccessFactors investment with our Managed Services.
                      </p>
                      <p align="justify">
                        Chachapoya HXM experts ensure you are prepared for the
                        road ahead – from a modest level of support to a more
                        comprehensive plan, we've got you covered.{' '}
                      </p>
                      <p align="justify">
                        Release updates and functionality improvements happen
                        frequently. These features can be very beneficial when
                        managed well but can become a burden without the right
                        support.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-area bg-color fix area-padding">
          <div className="container">
            <div className="row">
              <div className="single-machine row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="machine-text text-center">
                    <h3>QUESTIONS? WE’VE GOT ANSWERS.</h3>
                    <p className="text-center">
                      You don’t learn everything in life by reading a manual.
                      So, if you have questions about our products, services,
                      implementation, getting started, or anything else, please
                      ask us. Our highly trained staff is ready to help.
                    </p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Contact Us
                    </Link>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManagedServices;
