// Button.js
import React from 'react';
import { Link } from 'react-router-dom';

const Button = () => (
  <a  href="https://meetings.hubspot.com/gavin143">
    <button className='btn btn-primary'><span style={{fontSize:"15px" ,fontSmooth:"auto"}}>SCHEDULE A CONSULTATION</span>  </button>
  </a>
);

export default Button;
