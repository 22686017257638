import React from "react";
import "./Post.css";

const Post = ({ post, index }) => {
  return (
    <div className="post">
      <div className="post-header">
        <h2 className="post-title">{post.title}</h2>
        <p className="post-author">by {post.author}</p>
      </div>
      <div className="post-body">
        <p>{post.body}</p>
      </div>
      <div className="post-footer">
        <img src={post.imgUrl} alt={`post ${index + 1}`} />
      </div>
    </div>
  );
};

export default Post;
