import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BusinessTransformation extends Component {
  render() {
    return (
      <div>
        <div className="page-area">
          <div className="breadcumb-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb text-center">
                  <div className="section-headline white-headline text-center">
                    <h3>Digital Transformations</h3>
                  </div>
                  {/* <ul>
                    <li className="home-bread">Home</li>
                    <li>Solutions</li>
                    <li>Business Transformation</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-services-page area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="page-head-left">
                  <div className="single-page-head">
                    <div className="left-menu">
                      <ul>
                        <li  >
                          <Link to="/packages">Packages</Link>
                        </li>
                        <li>
                          <Link to="/integrations">Integration Services</Link>
                        </li>
                        <li  className="active">
                          <Link to="/business-transformation">
                            Digital Transformations
                          </Link>
                        </li>
                        <li>
                          <Link to="/implementation">Implementations</Link>
                        </li>
                        <li>
                          <Link to="/managed-services">Managed Services</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm-8 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="single-well mar-well">
                      <h4>BUSINESS TRANSFORMATION SERVICES</h4>
                      <p align="justify">
                        We help you get the most out of your HR IT investments
                        by guiding you through the crucial post-implementation
                        phase. Our industry experts help customers transform
                        their business with HR analytics, change management, and
                        business process design.
                      </p>

                      <h4>BUSINESS PROCESS DESIGN</h4>
                      <p align="justify">
                        {' '}
                        Prepare the organization through process review and
                        process re-engineering activities that support the
                        design of your future state.
                      </p>
                      <h4>TRAINING AND DIGITAL ADOPTION</h4>
                      <p align="justify">
                        {' '}
                        Make sure training and end-user adoption does not become
                        an afterthought with our guidance to drive best
                        practices in your instance.
                      </p>
                      <h4>CHANGE MANAGEMENT & STRATEGIC CONSULTING</h4>
                      <p align="justify">
                        {' '}
                        Approach your implementation with a strong foundation of
                        change management, focused on end-user adoption and
                        sustained system usage.{' '}
                      </p>
                      <h4>TRAINING & DIGITAL ADOPTION</h4>
                      <p align="justify">
                        Leverage Data Science experts to understand system
                        reporting capabilities and the power of analytics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-area bg-color fix area-padding">
          <div className="container">
            <div className="row">
              <div className="single-machine row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="machine-text text-center">
                    <h3>QUESTIONS? WE’VE GOT ANSWERS.</h3>
                    <p className="text-center">
                      You don’t learn everything in life by reading a manual.
                      So, if you have questions about our products, services,
                      implementation, getting started, or anything else, please
                      ask us. Our highly trained staff is ready to help.
                    </p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Contact Us
                    </Link>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BusinessTransformation;
