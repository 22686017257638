import React, { Component } from 'react';

class AboutCompany extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  componentDidUpdate(){
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <div className="page-area">
          <div className="breadcumb-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb text-center">
                  <div className="section-headline white-headline text-center">
                    <h3>About Chachapoya</h3>
                  </div>
                  {/* <ul>
                    <li className="home-bread">Home</li>
                    <li>About Us</li>
                    <li>About Chachapoya</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-area bg-color fix">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="tab-content" style={{ marginTop: '0px' }}>
                  <div className="tab-pane active" id="p-view-1">
                    <div className="tab-inner">
                      <div className="single-machine row">
                        <div className="col-md-12 col-sm-6 col-xs-12">
                          <div className="machine-text text-center">
                            <h3>
                              Our Journey: With 3 decades of tech industry
                              experience, Chachapoya is a holistic provider of
                              SAP SuccessFactors Solutions
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counter-area fix area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-6 col-xs-12">
                <div className="fun-text-all">
                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="fun_text wow fadeInUp" data-wow-delay="0.2s">
                      <span className="counter-icon">
                        <img src="img/exp.jpg" />
                      </span>
                      <span className="counter">30</span>
                      <h4>Years of Experience</h4>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="fun_text wow fadeInUp" data-wow-delay="0.3s">
                      <span className="counter-icon">
                        <img src="img/cer.jpg" />
                      </span>
                      <span className="counter">75</span>
                      <h4>Certified Consultants</h4>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="fun_text wow fadeInUp" data-wow-delay="0.4s">
                      <span className="counter-icon">
                        <img src="img/cus.jpg" />
                      </span>
                      <span className="counter">250</span>
                      <h4>Satisfied Customers</h4>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="fun_text wow fadeInUp" data-wow-delay="0.5s">
                      <span className="counter-icon">
                        <img src="img/loc.jpg" />
                      </span>
                      <span className="counter">6</span>
                      <h4>Global Locations</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-area fix ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h3>Organizational Highlights</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="blog-grid home-blog">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="single-blog">
                    <div className="blog-content">
                      <div className="blog-meta">
                        <span className="date-type">
                          <i className="fa fa-calendar"></i>
                          2015
                        </span>
                      </div>

                      <p>
                        Chachapoya Consulting, LLC Founded with focus on Digital
                        Transformation Strategies
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="single-blog">
                    <div className="blog-content">
                      <div className="blog-meta">
                        <span className="date-type">
                          <i className="fa fa-calendar"></i>
                          2015
                        </span>
                      </div>

                      <p>
                        EA Labs India(Pvt) Ltd founded as Global Delivery Arm of
                        Aasonn Inc., a core SAP Partner
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="single-blog">
                    <div className="blog-content">
                      <div className="blog-meta">
                        <span className="date-type">
                          <i className="fa fa-calendar"></i>
                          2017
                        </span>
                      </div>

                      <p>
                        Aasonn Inc acquired by Rizing Inc as part of SAP
                        SuccessFactors Partner Consolidation
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="single-blog">
                    <div className="blog-content">
                      <div className="blog-meta">
                        <span className="date-type">
                          <i className="fa fa-calendar"></i>
                          2019
                        </span>
                      </div>

                      <p>
                        EA Labs India(Pvt) Ltd becomes a wholly owned entity as
                        Rizing Inc decides to consolidate Global Delivery in SE
                        Asia
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="single-blog">
                    <div className="blog-content">
                      <div className="blog-meta">
                        <span className="date-type">
                          <i className="fa fa-calendar"></i>
                          2020
                        </span>
                      </div>

                      <p>
                        Chachapoya and EA Labs merge to become a leading
                        provider of SAP SuccessFactors solutions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="project-area bg-color area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 ">
                <div className="section-headline text-center">
                  <p>
                    We are an SAP Silver Partner and Boomi Certified
                    Partner, we were recognized as the 2020 Consulting
                    Organization of the Year by HR Tech Outlook
                  </p>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="text-center">
                <div className="col-md-3">
                  <div
                    className="single-awesome-project"
                    style={{ background: '#fff' ,paddingTop:"40px"}}
                  >
                    <div className="awesome-img">
                      <img
                        src="img/asari.jpg"
                        className="img-responsive"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="single-awesome-project"
                    style={{ background: '#fff' }}
                  >
                    <div className="awesome-img">
                      <img
                        src="img/sapsilver.jpg"
                        className="img-responsive"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div
                    className="single-awesome-project"
                    style={{ background: '#fff' ,paddingTop:"40px" }}
                  >
                    <div className="awesome-img">
                      <img src="img/boomi.jpg" className="img-responsive" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="single-awesome-project"
                    style={{
                      background: '#fff',
                      textAlign: 'center!important',
                    }}
                  >
                    <div className="awesome-img text-center">
                      <img
                        src="img/hr.jpg"
                        className="img-responsive img-center text-center"
                        alt=""
                        style={{paddingLeft:"45px", height:"120px"}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: '30px' }}>
          <div className="row">
            {/* <div className="blog-left-content"> */}
            <div className="col-md-12 col-sm-12 col-xs-12">
              {/* <div className="col-md-12 col-sm-12 col-xs-12"> */}
                    <div className="single-blog ">
                  <div class="col-md-6 mb-3">
                  <img src="img/gavinSIR.jpg" class="img-fluid" alt="Your Image" />
                   </div>
                   <div class="col-md-6">
               
                 <h2>Gavin Cockayne, Founder and Managing Partner</h2>
                  <p>Gavin has been a leader in the IT and Consulting business
                      for 3 decades. Starting in Europe where he lead
                      pan-European and global initiatives for a recognized
                      consumer products organization including the development
                      of the first ever relational database application for the
                      Finance and HR line of business.
                    </p>
                    <p>
                      Since re-locating to the US, Gavin has been engaged with
                      major consulting and software organizations such as PwC,
                       IBM Global Services, SuccessFactors and SAP.
                     </p>
                      <p>
                      During this time he has provided strategic and program
                      leadership to many organizations. In his most recent roles
                      at SuccessFactors and SAP he has been the organizational
                      leader for all aspects of consulting around Employee
                      Central.</p>
                      
                      </div>
                  {/* <div className="blog-content">
                    <h4>Gavin Cockayne, Founder and Managing Partner</h4>
                    <img src='img/gavinSIR.jpg' />
                    <p>
                      Gavin has been a leader in the IT and Consulting business
                      for 3 decades. Starting in Europe where he lead
                      pan-European and global initiatives for a recognized
                      consumer products organization including the development
                      of the first ever relational database application for the
                      Finance and HR line of business.
                     </p>
                      <p>
                      Since re-locating to the US, Gavin has been engaged with
                      major consulting and software organizations such as PwC,
                      IBM Global Services, SuccessFactors and SAP.
                      </p>
                      <p>
                      During this time he has provided strategic and program
                      leadership to many organizations. In his most recent roles
                      at SuccessFactors and SAP he has been the organizational
                      leader for all aspects of consulting around Employee
                      Central.
                      </p>
                      </div>
                     </div> */}
                      {/* </div> */}
                      </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="single-blog ">
                  <div className="blog-content">
                  <div class="col-md-6 mb-3">
                  <img src="img\RajuSir.png" class="img-fluid" alt="Your Image" />
                  </div>
                       <div class="col-md-6">
                        <h2>Raju Pasumarthi, Co-Founder, EA Labs, Global VP Operations</h2>
                      <p> 
                      Has a successful record as an SAP delivery executive for
                      Professional Services for all commercial and Public Sector
                      enterprises. With more than twenty years’ of experience
                      designing and delivering complex SAP-enabled
                      implementation projects/managed services engagements for
                      clients.{' '}
                    </p>
                    <p>
                      Mr. Raju Pasumarthi has lead and managed teams of 100+ to
                      successfully deliver complex multi-year programs, leading
                      large and complex business change projects involving
                      significant process reengineering and complex systems
                      implementation/systems integration, multiple
                      releases/work-streams, onshore/offshore resourcing, and
                      management of subcontract providers.
                    </p>
                    <ul style={{ listStyle: 'disc', marginLeft: '20px' }}>
                      <li>20+ years of SAP experience</li>
                      <li>
                        10+ years of experience as a Director/Vice President
                        level consulting firm
                      </li>
                      <li>
                        Business transformation and financial/logistics/human
                        capital management/technology process reengineering,
                        including related organizational change management and
                        training.
                      </li>
                      <li>
                        Design and implementation of post-implementation support
                        organizations, including SAP application management
                        services, and transition to steady-state business
                        operations.
                      </li>
                      <li>
                        {' '}
                        Strategic liaison between the business units and project
                        teams to ensure proper messaging and understanding of
                        requirements and technology, including Executive
                        Management.
                      </li>
                      <li>
                        Process-oriented for accountability, repeatability, and
                        proficiency. Strong analytical skills, managing complex
                        budgets, and schedules, mentoring project teams,
                        leadership calibration.
                      </li>
                    </ul> 
                        </div>
                    {/* <h4>
                      Raju Pasumarthi, Co-Founder, EA Labs, Global VP Operations
                    </h4>

                    <p>
                      Has a successful record as an SAP delivery executive for
                      Professional Services for all commercial and Public Sector
                      enterprises. With more than twenty years’ of experience
                      designing and delivering complex SAP-enabled
                      implementation projects/managed services engagements for
                      clients.{' '}
                    </p>
                    <p>
                      Mr. Raju Pasumarthi has lead and managed teams of 100+ to
                      successfully deliver complex multi-year programs, leading
                      large and complex business change projects involving
                      significant process reengineering and complex systems
                      implementation/systems integration, multiple
                      releases/work-streams, onshore/offshore resourcing, and
                      management of subcontract providers.
                    </p>
                    <ul style={{ listStyle: 'disc', marginLeft: '20px' }}>
                      <li>20+ years of SAP experience</li>
                      <li>
                        10+ years of experience as a Director/Vice President
                        level consulting firm
                      </li>
                      <li>
                        Business transformation and financial/logistics/human
                        capital management/technology process reengineering,
                        including related organizational change management and
                        training.
                      </li>
                      <li>
                        Design and implementation of post-implementation support
                        organizations, including SAP application management
                        services, and transition to steady-state business
                        operations.
                      </li>
                      <li>
                        {' '}
                        Strategic liaison between the business units and project
                        teams to ensure proper messaging and understanding of
                        requirements and technology, including Executive
                        Management.
                      </li>
                      <li>
                        Process-oriented for accountability, repeatability, and
                        proficiency. Strong analytical skills, managing complex
                        budgets, and schedules, mentoring project teams,
                        leadership calibration.
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="single-blog ">
                  <div className="blog-content">
                  <div class="col-md-6 mb-3">
                  <img src="img\danielSir.jpeg" class="img-fluid" alt="Your Image" />
                  </div>
                       <div class="col-md-6">
                        <h2>Daniel Ratna Raju Pasumarthy ,   Managing Director EA Labs India Pvt Ltd.</h2>
                      <p> 
                      A results driven, self-motivated and resourceful 
                      managing director with a proven ability to develop 
                      and strengthen management teams in order to maximise 
                      company profitability and efficiency.
                      Experienced leading and growing all sectors of
                      a business to make it a dynamic and progressive organisation. 

                    </p>
                    <p>
                    Possessing excellent communication skills and able to establish 
                    sustainable and profitable relationships with customers, 
                    suppliers and stakeholders across the world.
                    </p>
                    
                        </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutCompany;
