import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Integrations extends Component {
  render() {
    return (
      <div>
        <div className="page-area">
          <div className="breadcumb-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb text-center">
                  <div className="section-headline white-headline text-center">
                    <h3>Integration services</h3>
                  </div>
                  {/* <ul>
                    <li className="home-bread">Home</li>
                    <li>Solutions</li>
                    <li>Integration</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="single-services-page area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="page-head-left">
                  <div className="single-page-head">
                    <div className="left-menu">
                      <ul>
                        <li >
                          <Link to="/packages" >Packages</Link>
                        </li>
                        <li  className="active">
                          <Link to="/integrations">Integration Services</Link>
                        </li>
                        <li>
                          <Link to="/business-transformation">
                            Digital Transformations
                          </Link>
                        </li>
                        <li>
                          <Link to="/implementation">Implementations</Link>
                        </li>
                        <li>
                          <Link to="/managed-services">Managed Services</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm-8 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="single-well mar-well">
                      <h4>INTEGRATE & OPTIMIZE</h4>
                      <p align="justify">
                        You’ve taken the time and investment to implement SAP
                        SuccessFactors and elevate the HR team into a more
                        strategic role.
                      </p>
                      <p align="justify">
                        Our packages are based on SAP’s rapid deployment module
                        offerings coupled with EA Labs’s experience and
                        recommended practices.
                      </p>
                      <p align="justify">
                        You now have a better understanding of just how powerful
                        this cloud based application can be. Integrating SAP
                        SuccessFactors with your other related software and
                        systems is the key to improving workforce productivity,
                        business agility and end-user satisfaction.
                      </p>
                      <p align="justify">
                        The quickly growing landscape of cloud applications can
                        make things seem overwhelming or complicated. Our deep
                        SAP SuccessFactors knowledge and integrations background
                        will help ensure your SAP SuccessFactors integration
                        runs smoothly.
                      </p>
                      <p align="justify">
                        Integrating your HCM system with other business critical
                        systems will:
                      </p>
                      <ul className="marker-list">
                        <li>Streamline and automate business processes</li>
                        <li>Optimize workforce execution</li>
                        <li>Improve employee engagement and retention</li>
                        <li>
                          Create a consolidated and trusted system of record
                        </li>
                        <li>
                          Reduce manual data errors and strengthen
                          organizational controls
                        </li>
                      </ul>

                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-area bg-color fix area-padding">
          <div className="container">
            <div className="row">
              <div className="single-machine row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="machine-text text-center">
                    <h3>QUESTIONS? WE’VE GOT ANSWERS.</h3>
                    <p className="text-center">
                      You don’t learn everything in life by reading a manual.
                      So, if you have questions about our products, services,
                      implementation, getting started, or anything else, please
                      ask us. Our highly trained staff is ready to help.
                    </p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Integrations;
