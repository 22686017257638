import React, { Component } from "react";
import '../hubspotform.css';

class HubspotForm extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    document.body.appendChild(script);
    // console.log(script.src = "https://js.hsforms.net/forms/embed/v2.js");

    script.addEventListener("load", () => {
      window.hbspt.forms.create({
        portalId: "8728422",
        formId: "f7f28c73-9fb7-4725-aea3-71a8b25c5186",
        target: "#your-form-container-id",
      });
    });
  }

  render() {
    return (
      <div className="container" style={{marginTop: '-10px', paddingRight:'50%'}}>
      <h2 className="text-center" style={{color:'rgb(36, 53, 89)',fontSize:'24px', width:"fit-content"}}>SCHEDULE A FREddsfdsfE CONSULTATION</h2>
      <div className="row">
        <div className="col-md-8 offset-md-2 " formdiv="true"  style={{marginTop: '25px'}}>
          <div id="your-form-container-id"></div>
        </div>
      </div>
    </div>

    );
  }
}

export default HubspotForm;