export const menuItems = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About Us',
    submenu: [
      {
        title: 'About Company',
        url: '/about-company',
      },
      {
        title: 'Blog',
        url: '/chachapoyahcmdoodles.com',
      },
      {
        title: 'Why We Are Different',
        url: '/why-we',
      },
      {
        title: 'Partners',
        url: '/partners',
      },
    ],
  },
  {
    title: 'Solutions',
    submenu: [
      {
        title: 'Packages',
        url: '/packages',
      },
      {
        title: 'Integration Services',
        url: '/integrations',
      },
      {
        title: 'Digital Transformations',
        url: '/business-transformation',
      },
      {
        title: 'Implementations',
        url: '/implementation',
      },
      {
        title: 'Managed Services',
        url: '/managed-services',
      },
    ],
  },
  {
    title: "Advantages",
    url: "/advantages",
  },
  {
    title: "Contact Us",
    url: "/contact-us",
  },
];
