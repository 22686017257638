import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Implementation extends Component {
  render() {
    return (
      <div>
        <div class="page-area">
          <div class="breadcumb-overlay"></div>
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb text-center">
                  <div class="section-headline white-headline text-center">
                    <h3>Implementations</h3>
                  </div>
                  {/* <ul>
                    <li class="home-bread">Home</li>
                    <li>Solutions</li>
                    <li>Implementation Services</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="single-services-page area-padding">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12">
                <div class="page-head-left">
                  <div class="single-page-head">
                    <div class="left-menu">
                      <ul>
                        <li >
                          <Link to="/packages">Packages</Link>
                        </li>
                        <li>
                          <Link to="/integrations">Integration Services</Link>
                        </li>
                        <li>
                          <Link to="/business-transformation">
                            Digital Transformations
                          </Link>
                        </li>
                        <li  className="active">
                          <Link to="/implementation">Implementations</Link>
                        </li>
                        <li>
                          <Link to="/managed-services">Managed Services</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-8 col-xs-12">
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-well mar-well">
                      <h4>
                        YOUR BUSINESS GOALS + OUR EFFECTIVE STRATEGIES = PROJECT
                        SUCCESS
                      </h4>
                      <p align="justify">
                        Chachapoya configures systems that fit each individual
                        customer through collaboration and consultation while
                        recommending best practices. We tailor our proven
                        cloud-based HR, payroll and benefits solutions to help
                        you eliminate resource and budget waste and create
                        operational excellence. Our implementation process
                        aligns your key business drivers for an improved
                        end-user experience and a realignment of HCM toward a
                        more strategic focus.
                      </p>

                      <h4>PROJECT METHODOLOGY</h4>
                      <p align="justify">
                        {' '}
                        Our experience isn’t trivial – it’s essential.
                      </p>
                      <ul class="marker-list">
                        <li>
                          Holistic approach marries the intricacies of your
                          business with our industry best practices insights
                        </li>
                        <li>
                          Focus methodology garnered from over 5000 world-wide
                          implementations
                        </li>
                        <li>
                          HR Transformation expertise pilots you through change
                          management hurdles transforming your HR organization
                          for optimal workforce competitive advantage
                        </li>
                      </ul>

                      <h4>CUSTOM-FIT TECHNOLOGY</h4>
                      <p align="justify">
                        Cloud-based technology balanced with your unique
                        business requirements.
                      </p>
                      <p align="justify">
                        Our collaborative and consultative approach ensures that
                        the technology we configure and implement fits your
                        organization and meets your needs
                      </p>

                      <h4>CONSULTING SERVICES</h4>
                      <p align="justify">
                        On-demand, dedicated consulting services.{' '}
                      </p>
                      <p align="justify">
                        Supports your SuccessFactors applications administration
                        and internal talent management needs{' '}
                      </p>
                      <p align="justify">
                        Bridges gaps and provides your HR team with the
                        on-demand answers to extract the full value from your
                        SuccessFactors investment.{' '}
                      </p>
                      <p align="justify">
                        Response when you need it, during the time zone of your
                        choice.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-area bg-color fix area-padding">
          <div class="container">
            <div class="row">
              <div class="single-machine row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="machine-text text-center">
                    <h3>QUESTIONS? WE’VE GOT ANSWERS.</h3>
                    <p class="text-center">
                      You don’t learn everything in life by reading a manual.
                      So, if you have questions about our products, services,
                      implementation, getting started, or anything else, please
                      ask us. Our highly trained staff is ready to help.
                    </p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Contact Us
                    </Link>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Implementation;
