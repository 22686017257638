import React from "react";
import "./Posts.css";
import Post from "./Post";
import Archives from "./Archives";
import Pagination from "./Pagination";
import grid_0 from './grid_0.webp';

const Posts = () => {
  const blogPosts = [
   {
        title: "Welcome to the new blog for Chachapoya Consulting, Bringing Human Capital Management To The Cloud",
        body: `Welcome to the new blog for Chachapoya Consulting. We focus on delivering strategic guidance to organizations seeking to embark on the journey from a traditional on-premise Human Capital Management platform to the cloud. 

        Cloud computing is real and it is working hard today for all enterprises and all types. The cloud works for anything from a few hundred employees to the Fortune 50 organizations. 
        
        Where are you located in your proposed or actual migration to the cloud? Do you know what it has to offer to you and are you aware of the security issues and the drawbacks that exist in the cloud? How do you mitigate problems in the migration and ensure security?
        
        We will be providing industry related information and our insight to the process of bringing Human Capital Management to the cloud.  Visit us on the web at www.chachapoyaconsulting.com.`,
        author: "Chachapoyahxm",
        imgUrl:
          grid_0,
      },
    // ... other blog posts
  ];

  return (
<div>
    
    <div className="posts-container">
        
      <div className="posts">
        {blogPosts.map((post, index) => (
          <Post key={index} index={index} post={post} />
        ))}
      </div>
      <div className="archives">
        <Archives />
      </div>
    </div>
    <Pagination totalPages={1} currentPage={1}/>
    </div>
  );
};

export default Posts;
