import React, { Component } from 'react';
import HubspotForm from './HubspotForm';

class ContactUs extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  componentDidUpdate(){
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <div className="page-area">
          <div className="breadcumb-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb text-center">
                  <div className="section-headline white-headline text-center">
                    <h3>Contact Us</h3>
                  </div>
                  {/* <ul>
                    <li className="home-bread">Home</li>
                    <li>Contact Us</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-area area-padding">
          <div className="container">
            <div className="row">
              <div className="contact-inner">
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="contact-icon text-center">
                    <div className="single-icon"  style={{marginTop:"-30px", paddingTop:"30px",marginBottom:"30px"}}>
                      <i className="ti-mobile"></i>
                      <p>
                        Call : +1 303 400 6401
                        {/* <br />
                        <span>Call : +1 847 671 4558</span>*/}
                      </p> 
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="contact-icon text-center">
                    <div className="single-icon">
                      <i className="ti-email"></i>
                      <p>
                        Email : info@chachapoyahxm.com
                        <br />
                        <span> Email : hr@chachapoyahxm.com</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="contact-icon text-center" >
                    <div className="single-icon">
                      <i className="ti-location-pin"></i>
                      <p>
                        <span>
                           10233 SOUTH PARKER ROAD, SUITE 300 PARKER, CO 80134
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div>
                  <div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d58264414.73397025!2d-3.728243449999961!3d26.961272800000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1646239087670!5m2!1sen!2sin"
                      style={{ width: '100%', height: '490px' }}
                      allowfullscreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                 <div className="contact-form">
                 {/* <div className="row">
                    <form
                      id="contactForm"
                      method="POST"
                      action="#"
                      className="contact-form"
                    >
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required
                          data-error="Please enter your name"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <input
                          type="email"
                          className="email form-control"
                          id="email"
                          placeholder="Email"
                          required
                          data-error="Please enter your email"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <input
                          type="email"
                          className="phno form-control"
                          id="phno"
                          placeholder="Ph No"
                          required
                          data-error="Please enter Ph No"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <input
                          type="email"
                          className="phno form-control"
                          id="phno"
                          placeholder="Company Name"
                          required
                          data-error="Please enter Company Name"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <input
                          type="text"
                          id="msg_subject"
                          className="form-control"
                          placeholder="Subject"
                          required
                          data-error="Please enter your message subject"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <textarea
                          id="message"
                          rows="7"
                          placeholder="Massage"
                          className="form-control"
                          required
                          data-error="Write your message"
                        ></textarea>
                        <div className="help-block with-errors"></div>
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                        <button
                          type="submit"
                          id="submit"
                          className="add-btn contact-btn"
                        >
                          Send Message
                        </button>
                        <div
                          id="msgSubmit"
                          className="h3 text-center hidden"
                        ></div>
                        <div className="clearfix"></div>
                      </div>
                    </form>
                  </div>*/}
                </div> 
<HubspotForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
