import React, { Component } from 'react';

class Partners extends Component {
  render() {
    return (
      <div>
        <div className="page-area">
          <div className="breadcumb-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb text-center">
                  <div className="section-headline white-headline text-center">
                    <h3>About Chachapoya</h3>
                  </div>
                  {/* <ul>
                    <li className="home-bread">Home</li>
                    <li>About Us</li>
                    <li>Partners</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-area bg-color fix area-padding">
          <div className="container">
            <div className="row">
              <div className="single-machine row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="machine-text text-center">
                    <h3>OUR BEST-IN-CLASS PARTNERS EXCEL AT WHAT THEY DO</h3>
                    <p className="text-center">
                      CHACHAPOYA HXM AS A SERVICE SOLUTIONS, WHETHER
                      FULL-SERVICE OR A LA CARTE, LEVERAGE WORLD-WIDE EXPERTISE
                      WITH INDUSTRY LEADING, BEST-IN-CLASS PARTNERS WHO EXCEL AT
                      WHAT THEY DO, SO THAT OUR CUSTOMERS GET WHAT THEY WANT,
                      EXACTLY HOW THEY WANT IT..
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container area-padding">
          <div className="row">
            <div className="col-md-3">
              <a href=" https://www.sap.com/india/index.html" target="_blank">
                <img src="img/ssf.png" className="img-responsive" alt="" />
              </a>
            </div>
            <div className="col-md-9">
              <div className="partner">
                <h4>SAP® SuccessFactors®</h4>
                <p></p>
                <p align="justify">
                  HXM: The evolution of HCM software You can change the world of
                  work for good with the next generation of human capital
                  management (HCM) software. SAP SuccessFactors Human Experience
                  Management Suite (SAP SuccessFactors HXM Suite) offers
                  individualized experiences and strategic solutions that
                  empower employees to reach their potential.
                </p>
                <p></p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-3">
              <a href=" https://boomi.com/" target="_blank">
                <img src="img/boomi.jpg" className="img-responsive" alt="" />
              </a>
            </div>
            <div className="col-md-9">
              <div className="partner">
                <h4> BOOMI</h4>
                <p align="justify">
                  Boomi AtomSphere, the industry’s #1 Integration Cloud,
                  connects any combination of cloud and on-premise applications
                  without coding, software, or appliances. ISVs and businesses
                  alike benefit by connecting to the industry’s largest network
                  of SaaS, PaaS, on-premise and cloud computing environments in
                  a seamless and fully self-service model.
                </p>
                <p></p>
              </div>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-md-3">
              <a href="https://rizing.com/" target="_blank">
                <img src="img/RizingHCM.png" className="img-responsive" alt="" />
              </a>
            </div>
            <div className="col-md-9">
              <div className="partner">
                <h4>RIZING</h4>
                <p align="justify">
                  Rizing’s objective is to enable every business that uses SAP
                  solutions to achieve a truly intelligent enterprise. We do
                  this for Human Capital Management, Enterprise Asset Management
                  and Consumer Industries with leading SAP technologies and our
                  own deep industry experience. Rizing provides services and our
                  proprietary apps leveraging the SAP Cloud Platform to Fortune
                  500 and small/medium enterprises. Our mission is always to
                  propel your organization along the transformation journey.
                </p>
                <p></p>
              </div>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-md-3">
              <a href="https://asari.in/" target="_blank">
                <img src="img/asari.jpg" className="img-responsive" alt="" />
              </a>
            </div>
            <div className="col-md-9">
              <div className="partner">
                <h4>Asari</h4>
                <p align="justify">
                  Asari is a single destination to digitize and transform your
                  HR environment into a more productive and result driven
                  function through SAP SuccessFactors.
                </p>
                <p></p>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    );
  }
}

export default Partners;
