import React, { Component } from 'react';
// import HubspotForm from './HubspotForm';
import Button from './Button';

class Home extends Component {
   
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  componentDidUpdate(){
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div>
        <div className="intro-area-2">
          <div className="intro-carousel">
            <div className="intro-content-2">
              <div className="slider-images">
                <img src="img/slider/h1.jpg" alt="" />
              </div>
              <div className="slider-content">
                <div className="display-table">
                  <div className="display-table-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="layer-1 wow fadeInUp"
                            data-wow-delay="0.3s"
                          >
                            <h2 className="title2">
                              CHACHAPOYA empowers your HR team to transform your
                              enterprise
                            </h2>
                          </div>
                          <div
                            className="layer-3 wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                           <Button />
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
{/*             
            <div className="intro-content-2 slide-2">
              <div className="slider-images">
                <img src="img/slider/h2.jpg" alt="" />
              </div>
              <div className="slider-content">
                <div className="display-table">
                  <div className="display-table-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="layer-1 wow fadeInUp"
                            data-wow-delay="0.3s"
                          >
                            <h2 className="title2">
                              Are you passionate about enhancing HCM in the
                              cloud?
                            </h2>
                          </div>
                          <div
                            className="layer-3 wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <a href="#" className="ready-btn left-btn ">
                              View More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="intro-content-2 slide-3">
              <div className="slider-images">
                <img src="img/slider/h3.jpg" alt="" />
              </div>
              <div className="slider-content">
                <div className="display-table">
                  <div className="display-table-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="layer-1 wow fadeInUp"
                            data-wow-delay="0.3s"
                          >
                            <h2 className="title2">
                              We offer unmatched application management
                              services.
                            </h2>
                          </div>
                          <div
                            className="layer-3 wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <a href="#" className="ready-btn left-btn ">
                              View More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="welcome-area fix area-padding">
          <div className="container">
            <div className="section-headline text-center">
              <h3>Solutions</h3>
              <p align="center">
                WE PROVIDE HUMAN CAPITAL MANAGEMENT SOLUTIONS IN THE CLOUD.
              </p>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="all-wel-services">
                  <div
                    className="well-services wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{ paddingTop: '20px' }}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="/implementations">
                        <i className="flaticon-052-project-plan"></i>
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h5>IMPLEMENTATIONS</h5>
                        <p>
                          Solutions that fit each individual customer through
                          collaboration while recommending best...{' '}
                        </p>
                        <a href="implementation.php" className="btn btn-default">
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="well-services wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{ paddingTop: '20px' }}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="/packages">
                        <i className="flaticon-058-suitcase"></i>
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h5>PACKAGES</h5>
                        <p>
                          Our packages are based on SAP’s rapid deployment
                          module offerings coupled with our experience and...{' '}
                        </p>
                        <a href="/packages" className="btn btn-default">
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="well-services wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{ paddingTop: '20px' }}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="/integrations">
                        <i className="flaticon-062-workflow"></i>
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h5>INTEGRATIONS</h5>
                        <p>
                          integrations background will help ensure your SAP
                          SuccessFactors integration runs smoothly ...
                        </p>
                        <a href="/integrations" className="btn btn-default">
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="well-services wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{ paddingTop: '20px' }}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="#">
                        <i className="flaticon-034-hr-manager"></i>
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h5>MANAGED SERVICES</h5>
                        <p>
                          Get the most value out of your SAP HCM solution and
                          SuccessFactors investment with our Managed
                          Services....
                        </p>
                        <a href="/managed-services" className="btn btn-default">
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-area about-2 fix">
          <div className="container-full">
            <div className="row">
              <div className="col-md-5 col-sm-4 col-xs-12">
                <div className="about-image"></div>
              </div>
              <div className="col-md-7 col-sm-8 col-xs-12 " style={{marginBottom:"30px"}}>
                <div className="support-all about-content">
                  <div className="section-headline right-headline white-headline">
                    <h4 style={{ color: '#FFF' }}>
                      HUMAN CAPITAL MANAGEMENT{' '}
                      <span className="color">IN THE CLOUD</span>
                    </h4>
                    <p align="justify" style={{ marginBottom: '25px' }}>
                      Chachapoya Consulting focuses on delivering strategic
                      guidance to organizations seeking to embark on the journey
                      from a traditional on-premise Human Capital Management
                      platform to the cloud.
                    </p>
                    <p align="justify" style={{ marginBottom: '27px' }}>
                      Cloud computing is real and it is working hard today for
                      all enterprises and all types. The cloud works for
                      anything from a few hundred employees to the Fortune 50
                      organizations.
                    </p>

                    <p align="justify">
                      Where are you located in your proposed or actual migration
                      to the cloud? Do you know what it has to offer to you and
                      are you aware of the security issues and the drawbacks
                      that exist in the cloud? How do you mitigate problems in
                      the migration and ensure security?
                    </p>

                    <br />

                    <p>
                      <a href="#">read more...</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-area  fix area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft : "400px"}}>
                <div className="tab-menu">
                  <ul className="nav nav-tabs" role="tablist" >
                    <li className="active" style={{width: "max-content"}}>
                      <a href="#p-view-1" role="tab" data-toggle="tab">
                        <span className="cha-title">CHACHAPOYA ADVANTAGE AND DIFFERENCE</span>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#p-view-2" role="tab" data-toggle="tab">
                        <span className="cha-title">THE CHACHAPOYA DIFFERENCE</span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="tab-content">
                  <div className="tab-pane active" id="p-view-1">
                    <div className="tab-inner">
                      <div className="single-machine row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="tabe-img">
                            <img
                              src="img/MySuccessPlanwheel.png"
                              className="img-responsive"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="machine-text">
                            <h3>Our Approach is your advantage</h3>
                            <p align="justify">
                              We believe knowledge is a key to early success in
                              this stage – we take the time to walk the project
                              team and project sponsors through what the
                              solution has to offer to bring everyone up to the
                              same playing field so they feel knowledgeable
                              about what is available.
                              <br />
                              <br />
                              The Chachapoya SuccessPlan is our proven
                              methodology for success – insurance for your SAP®
                              SuccessFactors® investment.
                              <br />
                              <br />
                              Our roadmap provides the tools for every step
                              along your journey to ensure stakeholder
                              alignment, effective project planning,
                              comprehensive change Additionally, we deliver post
                              go-live services to ensure your investment
                              continues to deliver value well into the future –
                              your 'toolbox' for a complete lifecycle plan.
                              management, and user adoption. Utilizing SAP’s
                              Activity LifeCycle Management & Activate
                              Methodologies, Chachapoya develops an holistic
                              SuccessPlan for each and every Customer. We look
                              to align the overall HXM Strategic Plan,
                              Processes& Systems, Policies & Metrics to enhance
                              and focus in on the overall experience for each
                              and every individual touched by the migration to
                              SuccessFactors.
                              <br />
                              <br />
                              Additionally, we deliver post go-live services to
                              ensure your investment continues to deliver value
                              well into the future – your 'toolbox' for a
                              complete lifecycle plan.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="p-view-2">
                    <div className="tab-inner">
                      <div className="single-machine row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="tabe-img">
                            <img
                              src="img/section-image-1-1.png"
                              className="img-responsive"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="machine-text">
                            <p align="justify">
                              We can support you in any and all of your human
                              capital management initiatives. To help focus your
                              needs, check out these five fast facts on why you
                              should call Chachapoya COnsulting.
                              <br />
                              Chachapoya configures systems that fit each
                              individual customer through collaboration and
                              consultation while recommending best practices. We
                              tailor our proven cloud-based HR, payroll and
                              benefits solutions to help you eliminate resource
                              and budget waste and create operational
                              excellence. Our implementation process aligns your
                              key business drivers for an improved end-user
                              experience and a realignment of HCM toward a more
                              strategic focus.
                              <br />
                              <br />
                              <a href="#">read more...</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="project-area bg-color area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 ">
                <div className="section-headline text-center">
                  <h3>Partners</h3>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="text-center">
                <div className="col-md-3">
                  <div
                    className="single-awesome-project"
                    style={{ background: '#fff' }}
                  >
                    <div className="awesome-img">
                      <img
                        src="img/sapsilver.jpg"
                        className="img-responsive"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="single-awesome-project"
                    style={{ background: '#fff' , paddingTop:"47px" }}
                  >
                    <div className="awesome-img">
                      <img
                        src="img/asari.jpg"
                        className="img-responsive"
                        alt="" 
                        style={{paddingRight:"10px", paddingLeft:"10px"}}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div
                    className="single-awesome-project"
                    style={{ background: '#fff' , paddingTop:"35px"}}
                  >
                    <div className="awesome-img">
                      <img src="img/boomi.jpg" className="img-responsive" alt=""  style={{paddingRight:"10px", paddingLeft:"10px"}} />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="single-awesome-project"
                    style={{ background: '#fff' }}
                  >
                    <div className="awesome-img">
                      <img src="img/acc.jpg" className="img-responsive" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="reviews-area area-padding" style={{paddingTop:"10px", marginTop:"20px"}}>
            <div className="container">
                <div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<div className="section-headline text-center">
                            <h3>Customer Testimonials</h3>
                            <p>Don't just take our word for it. See what happy clients say!</p>
						</div>
					</div>
				</div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="Reviews-content">
                            <div className="testimonial-carousel item-indicator">
                                <div className="single-testi">
                                    <div className="testi-text">
                                        <div className="clients-text">
                                            <div className="client-rating">
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                            </div>
                                            <p style={{ fontWeight: 'bold',fontStyle: 'italic' ,fontSize:"20px" }}>"Amazing support with SSO and integration issues"</p>
                                            <div className="testi-img ">
                                                <div className="guest-details">
                                                    <h4>Edward</h4>
                                                      <p> Mgr, Cloud Security & Integration</p>
                                                    {/* <span className="guest-rev">General customer</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-testi">
                                    <div className="testi-text">
                                        <div className="clients-text">
                                            <div className="client-rating">
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                            </div>
                                            <p style={{ fontWeight: 'bold',fontStyle: 'italic' ,fontSize:"20px" }}>"Working over the weekend on the data center move - stepped up on Sunday and Monday to verify and assist with issues - they were phenomenal!"</p>
                                            <div className="testi-img ">
                                                <div className="guest-details">
                                                    <h4>Charlotte</h4>
                                                    <p> Director, HRIS </p>
                                                    {/* <span className="guest-rev">General customer</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-testi">
                                    <div className="testi-text">
                                        <div className="clients-text">
                                            <div className="client-rating">
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                            </div>
                                            <p style={{ fontWeight: 'bold',fontStyle: 'italic' ,fontSize:"20px" }}>"Good team came together seamlessly and effortlessly"</p>
                                            <div className="testi-img ">
                                                <div className="guest-details">
                                                    <h4>Daniel</h4>
                                                    <p> Senior Manager, HR Operations</p>
                                                    {/* <span className="guest-rev">General customer</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-testi">
                                    <div className="testi-text">
                                        <div className="clients-text">
                                            <div className="client-rating">
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                                <a href="#"><i className="ti-star"></i></a>
                                            </div>
                                            <p style={{ fontWeight: 'bold',fontStyle: 'italic' ,fontSize:"20px" }}>"I would like to commend the dedication to delivering the support needed to meet the deadline for Russia Data Center enablement"</p>
                                            <div className="testi-img ">
                                                <div className="guest-details">
                                                    <h4>Graham</h4>
                                                    <p> Senior Manager, Global HR </p>
                                                    {/* <span className="guest-rev">General customer</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Home;
