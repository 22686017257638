import React from "react";
import Posts  from "./Posts";

class Blog extends React.Component{
    componentDidMount() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      componentDidUpdate(){
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    render(){
        return (
            
                <div style={{marginTop:'100px'}}>
              <Posts />
               </div>
        )
    }
}

export default Blog;