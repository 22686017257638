import React, { Component } from 'react';

class Advantanges extends Component {
  render() {
    return (
      <div>
        <div className="page-area">
          <div className="breadcumb-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb text-center">
                  <div className="section-headline white-headline text-center">
                    <h3>Our Advantages</h3>
                  </div>
                  {/* <ul>
                    <li className="home-bread">Home</li>
                    <li>Advantage</li>
                    <li>Careers</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-area  fix area-padding">
          <div className="container">
            <div className="row">
              <div className="single-machine">
                <div className="col-md-5 col-sm-6 col-xs-12">
                  <div className="machine-text text-center">
                    <img
                      src="img/MySuccessPlanwheel.png"
                      className="img-responsive"
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-7 col-sm-12 col-xs-12">
                  <div className="machine-text">
                    <p align="justify">
                      MySuccess Plan is our proven methodology for success –
                      insurance for your SAP® SuccessFactors® investment. Our
                      roadmap provides the tools for every step along your
                      journey to ensure stakeholder alignment, effective project
                      planning, comprehensive change management, and user
                      adoption. Include post go-live services to ensure your
                      investment continues to deliver value well into the future
                      – your ‘toolbox’ for a complete lifecycle plan.
                    </p>

                    <h3>
                      STARTING OFF ON THE RIGHT FOOT IS CRITICAL TO PROJECT
                      SUCCESS{' '}
                    </h3>
                    <h5>MyKickoff</h5>
                    <p align="justify">
                      This initial stage of the overall plan is where we put
                      your Project Vision Statement together.
                      <br />
                      After MyKickoff, you’ll walk away with a resource
                      planning/priority plan, success metrics, project
                      structure, documentation, and more.
                    </p>

                    <h3>YOUR DESIGN AND SYSTEM COME TO LIFE</h3>
                    <h5>MySolution</h5>
                    <p align="justify">
                      The stage starts off with setting up a deployment roadmap
                      to look at the current and future phases of the project
                      and match up with critical business needs to get everyone
                      on the same page.
                      <br />
                      <br />
                      We believe knowledge is a key to early success in this
                      stage – we take the time to walk the project team and
                      project sponsors through what the solution has to offer to
                      bring everyone up to the same playing field so they feel
                      knowledgeable about what is available.
                      <br />
                      <br />
                      We will work together to layout a system map to
                      pictorially create a current state and future state system
                      roadmap and decommission strategy.
                      <br />
                      <br />
                      When ready, the project team will finalize an integrated
                      project plan to ensure we keep everyone accountable and on
                      task.
                      <br />
                      <br />
                      Implementation begins with a review of our best practice
                      system design and a knowledge transfer session to
                      facilitate.
                      <br />
                      <br />
                      Whether you select RDS (best practice with minimal
                      decisions in one iteration), Standard (best practice with
                      more choices and two iterations) or Enterprise (best
                      practice start with largest decision making possibility
                      and three iterations).
                    </p>

                    <h3>SEE RESULTS</h3>
                    <h5>MyROI</h5>
                    <p align="justify">
                      With MySuccess Plan we’ve built in data access so you can
                      measure the success of your rollout. Dynamic analytics are
                      available to check user adoption and success metrics!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-area bg-color fix area-padding">
          <div className="container">
            <div className="row">
              <div className="single-machine row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="machine-text text-center">
                    <h3>QUESTIONS? WE’VE GOT ANSWERS.</h3>
                    <p className="text-center">
                      You don’t learn everything in life by reading a manual.
                      So, if you have questions about our products, services,
                      implementation, getting started, or anything else, please
                      ask us. Our highly trained staff is ready to help.
                    </p>
                    <a href="/contact-us" className="btn btn-primary">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Advantanges;
