import React, { Component, useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import AboutCompany from '../components/AboutCompany';
import Advantanges from '../components/Advantanges';
import BusinessTransformation from '../components/BusinessTransformation';
import Careers from '../components/Careers';
import ContactUs from '../components/ContactUs';
import Home from '../components/Home';
import Implementation from '../components/Implementation';
import Integrations from '../components/Integrations';
import ManagedServices from '../components/ManagedServices';
import Packages from '../components/Packages';
import Partners from '../components/Partners';
import Sitemap from '../components/Sitemap';
import WhyWe from '../components/WhyWe';
import { menuItems } from "../menuItems";


const Header1 = () => {
  const MenuItems = ({ items }) => {
    const [dropdown, setDropdown] = useState(false);

    return (
      <li
        className="menu-items"
      >
        {items.submenu ? (
          <>
            <a
              className="pages" style={{cursor: 'pointer'}}
              onClick={() => setDropdown((e) => !e)}
            >
              {items.title}{" "}
            </a>
            <Submenu
              submenus={items.submenu}
              dropdown={dropdown}
            />
            {isMobile && (<a className="mean-expand" onClick={() => setDropdown((e) => !e)} style={{ fontSize: '18px', padding: "1rem !important" }}>{dropdown ? '-' : '+'}</a>)}
          </>
        ) :
      
        (
          items.title=="Blog"? <Link to="/blog" target="www.google.com">Blog</Link>:<Link to={items.url}>{items.title=="Home" || items.title=="Contact Us" ?"":items.title}</Link>
        )}
      </li>
    );
  };

  const Submenu = ({ submenus, dropdown }) => {
    return (
      <ul className="sub-menu" style={{ display: isMobile ? (dropdown ? 'block' : 'none') : 'block' }}>
        {submenus.map((submenu, index) => (
          <MenuItems items={submenu} key={index} />
        ))}
      </ul>
    );
  };

  const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const toggleClass = isOpen ? "meanclose" : '';
    return (
      <>
        {isMobile && <a className={`meanmenu-reveal ${toggleClass}`} style={{ right: '0px', left: 'auto', textAlign: 'center', textIndent: '0px', fontSize: '18px' }} onClick={toggle}>
        {isOpen ? 'X' : <><span></span><span></span><span></span></>}  
        </a>}
        <nav className='mean-nav'>
          <ul className="nav navbar-nav navbar-right" style={{ display: isMobile ? (isOpen ? 'block' : 'none') : 'block' }}>
            {menuItems.map((menu, index) => {
              return <MenuItems items={menu} key={index} />;
            })}
          </ul>
        </nav>
      </>
    );
  };

  const isMobile = typeof window !== `undefined` && window.innerWidth < 992;

  return (
    <div>
      {/* <div id="preloader"></div> */}

      {/* <Navbar /> */}
      <header className="header-one">
        <div className="topbar-area fix hidden-xs">
          <div className="container">
            <div className="row">
              <div className=" col-md-9 col-sm-9">
                <div className="topbar-left">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-envelope"></i>{' '}
                        info@chachapoyahxm.com
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-phone-square"></i> +1 303 400 6401
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="top-social">
                  <ul>
                    <li>
                      <Link to="/">
                        <img src="img/usa.jpg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <a href="https://ealabsipl.com" target="_blank">
                        <img src="img/india.jpg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sticker" className="header-area hidden-xs">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-3">
                <div className="logo">
                  <Link
                    className="navbar-brand page-scroll black-logo"
                    to="/"
                  >
                    <img src="img/logo/logo.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-md-9 col-sm-9">
              {/* <div className="header-right-link">                  
                  <a className='s-menu' href='https://application.chachapoyahxm.com' target="_blank">  {/* http://10.10.10.2:5003 ,https://application.chachapoyahxm.com/ */}
                  
                    {/* Employee Login */}
                  {/* </a> */}
                {/* </div> */}               
                <nav className="navbar navbar-default">
                  <div
                    className="collapse navbar-collapse"
                    id="navbar-example">
                    <div className="main-menu">
                      <Navbar />
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-menu-area hidden-lg hidden-md hidden-sm">
          <div className="container mean-container">
            <div className='mean-bar'>
              <nav className='mean-nav'>
                <Navbar />
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mobile-menu">
                  <div className="logo">
                    <Link to="/">
                      <img src="img/logo/logo.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about-company" component={AboutCompany} />
        <Route exact path="/why-we" component={WhyWe} />
        <Route exact path="/partners" component={Partners} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/packages" component={Packages} />
        <Route exact path="/integrations" component={Integrations} />
        <Route
          exact
          path="/business-transformation"
          component={BusinessTransformation}
        />
        <Route exact path="/implementation" component={Implementation} />
        <Route exact path="/managed-services" component={ManagedServices} />
        <Route exact path="/advantages" component={Advantanges} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/sitemap" component={Sitemap} />
      </Switch>
    </div>
  );
}

export default Header1;
